import React from 'react';

import useGetSiteHeaders from '@hooks/useGetSiteHeaders';
import Seo from '@mainComponents/Seo';
import PropTypes from 'prop-types';

const SiteHead = ({location}) => {
	let path = location.pathname;
	if ((path.length > 1) && (path.lastIndexOf('/') !== 0) ) {
		path = path.slice(0, path.lastIndexOf('/'));
	}
	const {siteHeader} = useGetSiteHeaders(path);
	return (
		<Seo
			imageUrl={siteHeader?.image?.url}
			title={siteHeader?.headline || undefined}
			description={siteHeader?.description || undefined}
			location={location}
		/>
	);
};

SiteHead.propTypes = {
	location: PropTypes.object.isRequired
};

export default SiteHead;