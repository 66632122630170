import React from 'react';

import Container from '@components/Container';
import FlexBox from '@components/layout/FlexBox';
import Wrapper from '@components/layout/Wrapper';
import Layout from '@mainComponents/Layout';
import settings from '@settings';

const Impressum = () => (
	<Layout
		pageTitle="Impressum"
		headerType='page-without-image'
		showHeader
		path="/impressum"
	>
		<Wrapper background={settings.colors.light}  >
			<Container>
				{/* <Divider gap='large' /> */}
				<FlexBox align='flex-start' direction='column' hasFullWidth>
					<div css={{width: 'auto', 'h3': {textAlign: 'left !important', marginBottom: settings.sizes.medium}}}>
						<h3 >
							Contact: 
						</h3>
						<p>
							Theresa Schimpfössl
						</p>
						<p>
							Flawia Krawczyk
						</p>
						<p>
							Hugstetter Str. 11
						</p>
						<p>
							79106 Freiburg
						</p>
						<p>
							E-Mail: <a href='mailto:nocogirls@nocogiels.com'>nocogirls@nocogirls.com </a>
						</p>
					</div>
				</FlexBox>
			</Container>
		</Wrapper>
	</Layout>
);

export default Impressum;